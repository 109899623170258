export default [
  {
    path: "/",
    component: () => import("../views/Home.vue"),
    name: "Home",
    meta: {name: "Home", title: "홈"},
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  }
];
