import {request, setAuthInHeader} from "@/api/request";
import {param} from "jquery";
// import {req} from "vuelidate/src/validators/common";


let $api = {
    $auth: { // 계정 로그인
        login(params) {
            return request.post("member/login", params).then(({data}) => data);
        },
    },
    $config: {
        getGroup(params) {
            return request.get(`group`, params).then(({data}) => data);
        },
        getTerms(params) {
            return request.get(`config`, params).then(({data}) => data);
        },
        updateTerms(params) {
            return request.post(`config/update`, params).then(({data}) => data);
        },
        createTerms(params) {
            return request.post(`config`, params).then(({data}) => data);
        },
        deleteTerms(params) {
            return request.post(`config/delete`, params).then(({data}) => data);
        }
    },
    $member:{
        getMember(params) {
            return request.get("member/admin", params).then(({ data }) => data);
        },
        createMember(params) {
            return request.post('member/mingle/pass', params).then(({data}) => data);
        },
        getMemberDetail(params) {
            return request.get(`member/info`, params).then(({data}) => data);
        },
        updateMember(params) {
            return request.post(`member/update`, params).then(({data}) => data);
        },
        updateMemberType(params) {
            return request.post(`member/admin/minglepass/update`, params).then(({data}) => data);
        },
        checkIdDuplicate(params) {
            return request.get('member/chk/id', params).then(({data}) => data);
        },
        checkNickDuplicate(params) {
            return request.get('member/chk/nick', params).then(({data}) => data);
        },
        getQuitMember(params) {
            return request.get(`member/out`, params).then(({data}) => data);
        },
    },
    $board: {
        getNotice(params) {
            return request.get("board/pnotice", params).then(({data}) => data);
        },
        createNotice(params) {
            return request.post("board/pnotice", params).then(({data}) => data);
        },
        updateNotice(params) {
            return request.post("board/pnotice/update", params).then(({data}) => data);
        },
        deleteNotice(params) {
            return request.post("board/pnotice/delete", params).then(({data}) => data);
        },
        getFaq(params) {
            return request.get("board/pfaq", params).then(({data}) => data);
        },
        createFaq(params) {
            return request.post("board/pfaq", params).then(({data}) => data);
        },
        updateFaq(params) {
            return request.post("board/pfaq/update", params).then(({data}) => data);
        },
        deleteFaq(params) {
            return request.post("board/pfaq/delete", params).then(({data}) => data);
        },
        getQna(params) {
            return request.get("board/pqna", params).then(({data}) => data);
        },
        updateQnaComment(params) {
            return request.post("board/pqna", params).then(({data}) => data);
        },
        updateQna(params) {
            return request.post("board/pqna/update", params).then(({data}) => data);
        },
        deleteQna(params) {
            return request.post("board/pqna/delete", params).then(({data}) => data);
        },
        getCategory(params) {
            return request.get(`board/pfaq/category`, params).then(({data}) => data);
        },
        createCategory(params) {
            return request.post(`board/pfaq/category`, params).then(({data}) => data);
        },
        updateCategory(params) {
            return request.post(`board/pfaq/category/update`, params).then(({data}) => data);
        },
        deleteCategory(params) {
            return request.post(`board/pfaq/category/delete`, params).then(({data}) => data);
        },
    },
    $handler: {
        authHeader: setAuthInHeader
    },
    $launch: {
        getLaunch(params) {
            return request.get(`launch`, params).then(({data}) => data);
        },
        createLaunch(params) {
            return request.post("launch", params).then(({data}) => data);
        },
        updateLaunch(params) {
            return request.post("launch/update", params).then(({data}) => data);
        },
        deleteLaunch(params) {
            return request.post("launch/delete", params).then(({data}) => data);
        },
    },
    $banner: {
        getBanner(params) {
            return request.get(`banner`, params).then(({data}) => data);
        },
        createBanner(params) {
            return request.post(`banner`, params).then(({data}) => data);
        },
        updateBanner(params) {
            return request.post(`banner/update`, params).then(({data}) => data);
        },
        deleteBanner(params) {
            return request.post(`banner/delete`, params).then(({data}) => data);
        },
    },
    $utility: {
        getUtility(params) {
            return request.get(`utility`, params).then(({data}) => data);
        },
        getUtilityCategory(params) {
            return request.get(`utility/category`, params).then(({data}) => data);
        },
        getUtilityTransaction(params) {
            return request.get(`utility/deal`, params).then(({data}) => data);
        },
        getUtilityManager(params) {
            return request.get(`utility/manager`, params).then(({data}) => data);
        },
        deleteUtility(params) {
            return request.post(`utility/delete`, params).then(({data}) => data);
        },
    },
    $point: {
        updatePoint(params) {
            return request.post(`point`, params).then(({data}) => data);
        },
        getPoint(params) {
            return request.get(`point`, params).then(({data}) => data);
        },
    },
    $nticket: {
        getNticket(params) {
            return request.get(`item/pass/admin`, params).then(({data}) => data);
        },
        getNticketDetail(params) {
            return request.get(`item/admin/find`, params).then(({data}) => data);
        },
        updateNticket(params) {
            return request.post(`item/update`, params).then(({data}) => data);
        },
        deleteNticket(params) {
            return request.post(`item/delete`, params).then(({data}) => data);
        },
        getNticketCategory(params) {
            return request.get(`itemcategory`, params).then(({data}) => data);
        },
        getCollect(params) {
            return request.get(`collect`, params).then(({data}) => data);
        },
        updateCollect(params) {
            return request.post(`collect/update`, params).then(({data}) => data);
        }
    },
    $file: {
        getFile(params) {
            return request.get("file", params).then(({data}) => data);
        },
        editorUpload(params) {
            return request.post("file/editorUpload", params).then(({data}) => data);
        },
        downLoad(params) {
            return request.get(params).then(({data}) => data);
        },
        updateFile(params) {
            return request.post(`file`, params).then(({data}) => data);
        },
        deleteFile(params) {
            return request.post("file/delete/" + params.a_idx).then(({data}) => data);
        }
    },
};
export default $api;
