import {request, setAuthInHeader} from "@/api/request";

const state = {
    UserInfo: {},
};

const getters = {
    getUserInfo: state => state.UserInfo,

};

const mutations = {
    updateUserInfo(state, obj) {
        state.UserInfo = obj.value;
    },
    resetUserInfo(state) {
        state.UserInfo = {};
    },
};

const actions = {
    setUserInfo({commit, rootState}, {token}) {
        setAuthInHeader(token);
        let params = {
            mt: 'self',
        }
        request.get('member', params)
            .then((res) => {
                let result = res.data.Data.Result;
                if (result.Info.type > 0 && result.List.length > 0) {

                    commit('updateUserInfo', {'value': result.List[0]});

                } else {
                    commit('updateUserInfo', {'value': {}})
                }

            }, () => {
                console.log('error');
            })
    },
    changeUserInfo({commit, rootState}, {data}) {
        commit('updateUserInfo', {'value': data});
    },
    initUserInfo({commit, rootState}, {data}) {
        commit('resetUserInfo');
    },
};

export default {namespaced: true, state, getters, mutations, actions}
